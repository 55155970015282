<template>
  <div class="login">
    <header-view></header-view>
    <div class="content">
      <div class="box">
        <!-- <div class="login-img">
          <img src="../../assets/images/login.png" alt="" />
        </div> -->
        <div class="title">个人信息</div>
        <div class="right">
          <el-form
            :model="loginForm"
            label-position="left"
            label-width="100px"
            :rules="loginRules"
            ref="loginForm"
          >
            <el-form-item prop="phone" label="手机号">
              <el-input
                placeholder="请输入手机号"
                v-model="loginForm.phone"
                disabled
              ></el-input>
            </el-form-item>
            <!-- <el-form-item prop="smsCode" label="验证码">
              <el-input
                placeholder="请输入短信验证码"
                v-model="loginForm.smsCode"
              ></el-input>
            </el-form-item> -->
            <!-- <el-form-item prop="password" label="登录密码">
              <el-input
                type="password"
                placeholder="请输入登录密码"
                v-model="loginForm.password"
              ></el-input>
            </el-form-item>
            <el-form-item prop="rePassword" label="确认密码">
              <el-input
                type="password"
                placeholder="请输入确认密码"
                v-model="loginForm.rePassword"
              ></el-input>
            </el-form-item> -->
            <el-form-item prop="relName" label="姓名">
              <el-input
                placeholder="请输入姓名"
                v-model="loginForm.relName"
              ></el-input>
            </el-form-item>
            <el-form-item prop="age" label="年龄">
              <el-input
                placeholder="请输入年龄"
                v-model="loginForm.age"
              ></el-input>
            </el-form-item>
            <el-form-item prop="email" label="电子邮箱">
              <el-input
                placeholder="请输入电子邮箱"
                v-model="loginForm.email"
              ></el-input>
            </el-form-item>
            <el-form-item prop="codeId" label="身份证/护照">
              <el-input
                placeholder="请输入证件号"
                v-model="loginForm.codeId"
              ></el-input>
            </el-form-item>
            <el-form-item prop="sex" label="性别">
              <el-radio-group v-model="loginForm.sex">
                <el-radio label="0">男</el-radio>
                <el-radio label="1">女</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item prop="company" label="工作单位">
              <el-input
                placeholder="请输入工作单位"
                v-model="loginForm.company"
              ></el-input>
            </el-form-item>
            <el-form-item prop="citys" label="所在属地">
              <el-cascader
                v-model="loginForm.citys"
                :options="cityList"
                :props="cityProps"
                @change="handleChange"
                style="width: 100%"
              ></el-cascader>
            </el-form-item>
            <el-form-item prop="address" label="详细地址">
              <el-input
                placeholder="请输入详细地址"
                v-model="loginForm.address"
              ></el-input>
            </el-form-item>
            <el-form-item prop="post" label="职务">
              <el-input
                placeholder="请输入职务"
                v-model="loginForm.post"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item prop="keyCode" label="密钥">
              <el-input
                placeholder="请输入密钥"
                v-model="loginForm.keyCode"
              ></el-input>
            </el-form-item> -->
          </el-form>
          <!-- <div class="zhuce">注册账号</div> -->
          <el-button
            :loading="loading"
            @click.native.prevent="handleLogin"
            class="btn"
            >保存信息</el-button
          >
        </div>
      </div>
    </div>
    <footer-view></footer-view>
  </div>
</template>

<script>
import HeaderView from "@/components/HeaderView";
import FooterView from "@/components/FooterView";
import { stuInfo, update } from "@/api/user";
import cityList from "../../utils/city";
export default {
  components: {
    HeaderView,
    FooterView,
  },
  data() {
    const validateUsername = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请输入手机号"));
      } else {
        callback();
      }
    };
    const validatePass = (rule, value, callback) => {
      if (value.length < 5) {
        callback(new Error("密码不能小于5位"));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        password: "",
        phone: "",
        rePassword: "",
        address: "",
        age: "",
        area: "",
        city: "",
        codeId: "",
        company: "",
        email: "",
        keyCode: "",
        post: "",
        province: "",
        relName: "",
        sex: "",
        companys: "",
        citys: "",
      },
      loginRules: {
        phone: [
          { required: true, trigger: "blur", validator: validateUsername },
        ],
        password: [
          { required: true, trigger: "blur", validator: validatePass },
        ],
        email: [{ required: true, message: "请输入联系邮箱", trigger: "blur" }],
        relName: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        rePassword: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
        ],
        post: [{ required: true, message: "请输入职务", trigger: "blur" }],
        company: [
          { required: true, message: "请输入工作单位", trigger: "blur" },
        ],
        codeId: [{ required: true, message: "请输入证件号", trigger: "blur" }],
        age: [{ required: true, message: "请输入年龄", trigger: "blur" }],
        address: [
          { required: true, message: "请输入详细地址", trigger: "blur" },
        ],
        sex: [{ required: true, message: "请选择性别", trigger: "change" }],
        citys: [{ required: true, message: "请选择所属地", trigger: "change" }],
      },
      loading: false,
      cityList: cityList.city,
      cityProps: {
        value: "region_name",
        label: "region_name",
      },
      timer: null,
      count: "",
      show: true,
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    handleChange(val) {
      console.log(val);
      this.loginForm.province = val[0];
      this.loginForm.city = val[1];
      this.loginForm.area = val[2];
    },
    getInfo() {
      stuInfo()
        .then((res) => {
          this.loginForm = res.data;
          this.loginForm.citys = [];
          this.loginForm.citys.push(res.data.province);
          this.loginForm.citys.push(res.data.city);
          this.loginForm.citys.push(res.data.area);
        })
        .catch((err) => {});
    },
    handleLogin() {
      console.log(this.loginForm.sex);
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          let data = this.loginForm;
          delete data["citys"];
          update(data)
            .then((res) => {
              this.$message({
                message: res.msg,
                type: "success",
              });
              this.getInfo();
            })
            .catch((err) => {});
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .box {
    width: 100% !important;
  }
  .right {
    width: 100% !important;
    padding: 0 10px !important;
  }
}
.login {
  background: #f9f9f9;
}
.content {
  min-height: calc(100vh - 100px);
  .box {
    width: 1170px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    padding-top: 20px;
    box-sizing: border-box;
    min-height: calc(100vh - 100px);
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    padding-bottom: 30px;

    .title {
      color: #333;
      font-size: 26px;
      display: flex;
      justify-content: center;
      margin-bottom: 25px;
      letter-spacing: 10px;
    }
    .right {
      width: 50%;
      flex-shrink: 0;
      padding: 0 50px;
      box-sizing: border-box;
      .codebtn {
        border: 1px solid #fe7b0a;
        height: 40px;
        border-radius: 4px;
        margin-left: 16px;
        color: #fe7b0a !important;
        width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
    .zhuce {
      color: #fff;
      font-size: 16px;
      display: flex;
      justify-content: flex-end;
    }
    .btn {
      width: 100%;
      height: 52px;
      background: #11aa8c;
      color: #fff;
      font-size: 20px;
      margin-top: 20px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
